<template>
  <div class="wives-page page">
    <div class="container-padding">
      <div class="wives-page__inner">
        <h2>Сила V единстве</h2>
        <div class="wives-page__content">
          <EditorJSComponent v-if="$options.filters.editor_has_text(wives)" :text="JSON.parse(wives)" />
          <p v-else>В данный момент информация отсутствует</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EditorJSComponent from "components/EditorJSComponent.vue";
import WIVES_PAGE from "@/graphql/pages/wives_page.graphql";

export default {
  name: "WivesPage",
  async asyncData({ apollo, store }) {
    await apollo.defaultClient
      .query({
        query: WIVES_PAGE,
      })
      .then(({ data }) => {
        store.state.wives_page = data.heroeswives;
      });
  },
  computed: {
    wives() {
      return this.$store.state.wives_page?.description;
    },
  },
  metaInfo: {
    title: "Сила V единстве",
  },
  components: {
    EditorJSComponent,
  },
};
</script>

<style lang="stylus">
.wives-page {

  &__inner {
    padding 126px 0 0 0
    display flex
    flex-direction column
    gap 50px
  }
}
</style>
